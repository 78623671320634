$box-bg: linear-gradient(
  210.96deg,
  rgba(17, 50, 80, 0.61) 0.01%,
  rgba(17, 50, 80, 0.7) 42.05%,
  rgba(17, 50, 80, 0) 104.81%
);
$box-border: 1px solid #ffffff17;
$box-radius: 14px 14px 0 0;

.dashboard {
  box-shadow: 0px 48px 69px 0px #17122bd8;
  border: $box-border;
  border-radius: $box-radius;

  .dashboard_header {
    padding: 2rem 2rem 0 2rem;
    background: $box-bg;
    border: $box-border;
    border-radius: $box-radius;
    h3 {
      font-weight: 700;
    }
  }

  .dashboard_bottom {
    padding: 1rem;
    border-radius: $box-radius;
    position: relative;

    .dashboard-items {
      border: $box-border;
      padding: 1rem;
      min-height: 400px;
      display: flex;
      justify-content: center;
      background: $box-bg;
      flex-wrap: wrap;
      .item-container {
        box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 0.35);
        background: linear-gradient(
          113deg,
          #53b9ea 1.19%,
          rgba(17, 50, 80, 0.42) 51.57%,
          #113250 97.92%
        );
        padding: 10px;
        margin: 10px;
        border-radius: 13px;
        width: 275px;
        height: 200px;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .title {
          font-weight: bolder;
          color: white;
        }
        .icon {
          margin-top: auto;
          margin-bottom: auto;
          height: 90px;
          width: 100px;
        }
      }
    }

    .application-btn {
      background-color: white;
      width: fit-content;
      color: #113250;
      border-radius: 6px;
      position: absolute;
      right: 170px;

      .unstyled-link {
        background-color: rgba(65, 145, 64, 1);
        color: white;
        border-radius: 4px;
        border: none;
        text-align: center;
      }

      .credit-form-btn {
        background-color: rgba(65, 145, 64, 1);
        color: white;
        border-radius: 4px;
        border: none;
        padding: 6px 14px;
      }
    }

    .customer-dropdown-box {
      background-color: white;
      width: fit-content;
      color: #113250;
      border-radius: 6px;
      position: absolute;
      right: 18px;
    }

    .customer-dropdown button {
      background: white;
      border: none;
    }

    .customer-copy-button {
      border: none;
      background: none;
    }
  }
}
