.swift-traffic {
  color: black;
  background-image: url("../../../public/backgrounds/cube.png"),
    radial-gradient(169.92% 169.92% at 50% -28.92%, #113250 0%, #062037 100%);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  text-transform: initial;

  .container {
    .swiftTrafficLogo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 100vh;
    }

    .eachPage {
      min-height: 100vh;
      // width: 70%;
      background-image: url("../../../public//backgrounds/triangle-shade.png");
      background-color: white;
      background-size: cover;
      background-repeat: no-repeat, repeat;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 30px 50px;
      border-radius: 8px;
      margin-bottom: 1rem;
    }

    .traffic-header {
      font-weight: 700;
      text-align: center;
    }

    .list-header {
      font-size: xx-large;
      font-weight: 700;
    }

    li {
      margin-bottom: 1.5rem;
      font-weight: 500;
      font-size: 21px;
    }

    .no-col {
      width: 8%;
    }
    .items-covered-col {
      width: 70%;
      white-space: normal; /* Allow text to wrap normally */
    }
    .initial-name-col {
      width: 20%;
    }

    th {
      font-size: large;
    }

    td {
      white-space: normal;
      font-size: large;
    }
    .Field_label {
      color: rgba(17, 50, 80, 1);
      font-weight: 600;
      text-transform: capitalize;
    }
    .signature-btn {
      display: flex;
      justify-content: flex-start;
      width: 50%;
      margin-left: auto;
      gap: 10px;
    }

    .signature-canvas {
      width: 100%;
      border: 1px solid black;
    }

    .credit-form-btn {
      background-color: rgba(65, 145, 64, 1);
      color: white;
      border-radius: 4px;
      border: none;
      padding: 7px 0;
      width: 20%;
      font-size: large;
    }
  }
}
