.credit-application {
  color: black;
  background-image: url("../../../public/backgrounds/cube.png"),
    radial-gradient(169.92% 169.92% at 50% -28.92%, #113250 0%, #062037 100%);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      width: 60%;
      background-image: url("../../../public//backgrounds/triangle-shade.png");
      background-color: white;
      background-size: cover;
      background-repeat: no-repeat, repeat;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 30px 50px;
      border-radius: 8px;

      &::-webkit-scrollbar {
        width: 0.3em;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 2px 2px 2px 2px rgba(255, 255, 255, 0.2);
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0c2b46;
        border-radius: 8px;
        &:hover {
          background-color: #0c2b46;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 1rem;
      }
    }

    .navbar {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media screen and (max-width: 768px) {
        height: fit-content;
        margin-left: 50px;
      }
    }

    .form-btn {
      margin: 0 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .unstyled-link {
        background-color: rgba(65, 145, 64, 1);
        color: white;
        border-radius: 4px;
        border: none;
        width: 30%;
        text-align: center;
      }

      .credit-form-btn {
        background-color: rgba(65, 145, 64, 1);
        color: white;
        border-radius: 4px;
        border: none;
        padding: 7px 0;
        width: 30%;
      }

      .credit-form-btn.disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }

    .stepper_Header,
    .Field_label {
      color: rgba(17, 50, 80, 1);
      font-weight: 600;
      text-transform: capitalize;
    }

    .signature-canvas {
      width: 100%;
    }
    .signature-btn {
      display: flex;
      justify-content: flex-start;
      width: 50%;
      margin-left: auto;
      gap: 10px;
    }
  }

  .welcome-details {
    .welcome-header {
      color: rgba(75, 171, 10, 1);
      font-weight: 600;
    }

    .welcome-content {
      color: rgba(17, 50, 80, 1);
      font-weight: 500;
      text-transform: initial;
    }
  }

  .signature-canvas {
    border: 1px solid black;
  }

  .progressBar {
    margin: 40px 25px;
    padding: 25px;
    margin-bottom: 50px;
  }

  .RSPBprogressBar {
    margin: auto;
    width: 58%;
    background-color: rgba(217, 217, 217, 1);
    height: 5px;
  }

  .RSPBstep {
    top: -30px;

    .ProgressSingleBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .progressLabel {
      margin-top: 6px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: small;
      width: 60%;
    }
  }

  .indexedStep {
    color: black;
    width: 60px;
    height: 60px;
    font-size: 24px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .indexedStep.accomplished {
    background: radial-gradient(
      56.58% 41.84% at 50% 50%,
      #569fe1 0%,
      #133451 100%
    );
    border: 3px solid rgba(75, 171, 10, 1);
    color: white;
  }

  .ConditionsOfCarriage {
    text-transform: initial;

    h4 {
      text-align: center;
      font-weight: 700;
    }
    line-height: 24px;
  }
}
